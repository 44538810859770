export default class BucketInfo {
  /**
   * コンストラクタ
   * @param {string} bucketName バケット名
   */
  constructor(
    bucketName = ''
  ) {
    this.bucketName = bucketName
  }
}
