const PLANNED = 0

export default class UpdateMissionStatus {
  constructor(
    missionDate = undefined
    , target = undefined
    , locationLat = undefined
    , locationLng = undefined
    , status = PLANNED
  ) {
    this.missionDate = missionDate
    this.target = target
    this.locationLat = locationLat
    this.locationLng = locationLng
    this.status = status
  }
}
