import BucketInfo from '@/models/bucket-info'
import repository from '@/repositories/rest-api/repository'

const basePath = `/api/v1/buckets`

const toBucketInfo = (bucketInfo) => {
  return new BucketInfo(
    bucketInfo['bucket_name']
  )
}

export default {
  checkBucket(bucketName) {
    return repository.post(`${basePath}/${bucketName}:check`)
      .then(function (response) {
        return toBucketInfo(response.data.body)
      })
      .catch(function (errorInfo) {
        return errorInfo
      })
      .finally(function () {})
  },
}
