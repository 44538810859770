import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const get = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}`)
      return response.data.body
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function originalImage(basePath) {
  baseUrl = basePath
  return {
    get: get(),
  }
}
