import mission from './mission-repository'

export default function missions(basePath, missionId) {
  const missionRepository = missionId ? new mission(`${basePath}/${missionId}`) : new mission(`${basePath}`)
  return {
    get: missionRepository.get(),
    post: missionRepository.post(),
    put: missionRepository.put(),
    hide: missionRepository.hide(),
    show: missionRepository.show(),
  }
}
