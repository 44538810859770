import UpdateDroneStatus from '@/models/websocket/update-drone-status'
import DeviceExtensionInfo from '@/models/device-extension-info'

// バックエンドでのデータの持ち方を見る限り、ミッション情報の一部としてのフライト情報というイメージみたいなので、
// 使用していなかったID類は削除（ミッションIDを主キーとしているようなので、ミッションIDは持つようにしておく）
// 利用するタイミングが出た場合に追加する想定
export default class FlightStatus {
  constructor(
    missionId = undefined
    , timestamp = undefined
    , gpsLocationAltitude = undefined
    , gpsLocationLat = undefined
    , gpsLocationLng = undefined
    , rtkLocationAltitude = undefined
    , rtkLocationLat = undefined
    , rtkLocationLng = undefined
    , rtkOrGpsValue = undefined
    , polylineLocationAltitude = undefined
    , polylineLocationLat = undefined
    , polylineLocationLng = undefined
    , flightTime = undefined
    , androidBattery = undefined
    , applicationStatus = 0
    , androidSysTemp = undefined
    , androidDiskSpace = undefined
    , deviceExtensionInfo = {}
  ) {
    this.missionId = missionId
    this.timestamp = timestamp
    this.gpsLocationAltitude = gpsLocationAltitude
    this.gpsLocationLat = gpsLocationLat
    this.gpsLocationLng = gpsLocationLng
    this.rtkLocationAltitude = rtkLocationAltitude
    this.rtkLocationLat = rtkLocationLat
    this.rtkLocationLng = rtkLocationLng
    this.rtkOrGpsValue = rtkOrGpsValue
    this.polylineLocationAltitude = polylineLocationAltitude
    this.polylineLocationLat = polylineLocationLat
    this.polylineLocationLng = polylineLocationLng
    this.flightTime = flightTime
    this.androidBattery = androidBattery
    this.applicationStatus = Number(applicationStatus)
    this.androidSysTemp = androidSysTemp
    this.androidDiskSpace = androidDiskSpace
    this.deviceExtensionInfo = new DeviceExtensionInfo(deviceExtensionInfo)
  }

  setUpdateDroneStatus(updateDroneStatus) {
    if (updateDroneStatus instanceof UpdateDroneStatus) {
      // ミッションIDは変更されることがない想定
      this.timestamp = updateDroneStatus.timestamp
      this.gpsLocationAltitude = updateDroneStatus.gpsLocationAltitude
      this.gpsLocationLat = updateDroneStatus.gpsLocationLat
      this.gpsLocationLng = updateDroneStatus.gpsLocationLng
      this.rtkLocationAltitude = updateDroneStatus.rtkLocationAltitude
      this.rtkLocationLat = updateDroneStatus.rtkLocationLat
      this.rtkLocationLng = updateDroneStatus.rtkLocationLng
      this.rtkOrGpsValue = updateDroneStatus.rtkOrGpsValue
      this.polylineLocationAltitude = updateDroneStatus.polylineLocationAltitude
      this.polylineLocationLat = updateDroneStatus.polylineLocationLat
      this.polylineLocationLng = updateDroneStatus.polylineLocationLng
      this.flightTime = updateDroneStatus.flightTime
      this.androidBattery = updateDroneStatus.androidBattery
      this.applicationStatus = updateDroneStatus.applicationStatus
      this.androidSysTemp = updateDroneStatus.androidSysTemp
      this.androidDiskSpace = updateDroneStatus.androidDiskSpace
      this.deviceExtensionInfo = new DeviceExtensionInfo(updateDroneStatus.deviceExtensionInfo)
    }
  }
}
