import device from './device-repository'
import metaData from './image-metadata-repository'
import thumbnailRepository from './thumbnail-repository'
import images from './images/repository-factory'
import mission from './mission-repository'
import bestshots from './bestshots/repository-factory'

export default function missions(basePath, missionId) {
  const missionRepository = missionId ? new mission(`${basePath}/${missionId}`) : new mission(`${basePath}`)
  return {
    getList: missionRepository.getList(),
    getFlightStatus: missionRepository.getFlightStatus(),
    getMissedLocation: missionRepository.getMissedLocation(),
    device: device(`${basePath}/${missionId}/device`),
    imageMetadata: metaData(`${basePath}/${missionId}/meta_list`),
    thumbnail: thumbnailRepository(`${basePath}/${missionId}/thumbnail_images`),
    images: (imageId) => images(`${basePath}/${missionId}/images`, imageId),
    exif: (imageId) => images(`${basePath}/${missionId}/images`, imageId),
    poles: (page, angle) => bestshots(`${basePath}/${missionId}`, undefined, page, angle),
    poleAndImages: (poleInfo) => bestshots(`${basePath}/${missionId}`, poleInfo),
  }
}
