export default {
  'mission': 'ミッション',
  'user': 'ユーザー',
  'delete-confirmation-title': '削除確認',
  'delete-confirmation-text': '削除された%{subject}は元に戻せませんが、削除しますか？',
  'required': '必須',
  'latest': '最新',
  'previous': '一つ前',
  'lat': '緯度',
  'lng': '経度',
}
