export default {
  'list-title': 'Mission List',
  'create-title': 'Mission Create',
  'edit-title': 'Mission Edit',
  'detail-title': 'Mission Detail',
  'create-button': 'Create',
  'update-button': 'Update',
  'edit-button': 'Edit',
  'hidden-button': 'Hidden',
  'redisplay-button': 'Redisplay',
  'cancel-button': 'Cancel',
  'selection-button': 'Selection',
  'all-missions': 'All missions',
  'my-missions': 'My missions',
  'hidden-mission': 'Hidden mission',
  'form': {
    'placeholder': {
      'mission-name': 'Mission Name',
      'mission-date': 'Implementation Date',
      'location-lat': 'latitude',
      'location-lng': 'longitude',
      'now-data': 'Now',
      'pilot-name': 'Pilot Name',
    },
    'select-text': 'Selecting the ...',
  },
  'error-message': {
    'select-pilot': 'Please select a pilot',
    'enter-mission-name': 'Please enter a mission name',
    'enter-implementation-date': 'Please enter the implementation date',
    'create-update-failed': 'Create/update failed',
    'some-itmes-failed': 'Some items are not connected correctly. Please check the message.',
    'device-error': 'Please make sure that the camera and mobile device are properly connected.',
    'get-device-id-failed': 'Failed to obtain device ID.',
    'get-device-region-failed': 'Failed to get region.',
    'get-live-view-info-failed': 'Failed to obtain live view information.Please check whether the HDMI cable is connected.',
    'start-live-view-failed': 'Failed to start live view.',
    'take-photo-failed': 'Failed to take photo.',
  },
  'label': {
    'mission-name': 'Mission name',
    'status': 'Status',
    'implementation-date': 'Implementation date',
  },
  'detail': {
    'mission-name': 'Mission name',
    'mission-date': 'Implementation date',
    'pilot-name': 'Pilot name',
    'location-lat': 'Latitude',
    'location-lng': 'Longitude',
    'status': 'Status',
    'flight-info': {
      'flight-title': 'Flight information',
      'timestamp': 'Time stamping',
      'Location': {
        'Location': 'location information',
        'gps': 'GPS',
        'rtk': 'RTK',
      },
      'direction': 'direction',
      'networkType': 'Network Type',
      'flightStatus': 'Flight Status',
      'rfStrength': 'RF Strength',
      'cellInfo': 'cell information',
      'flightTime': 'Flight time',
      'Battery': {
        'Battery': 'Battery',
        'drone': 'Drone',
        'camera': 'Camera',
        'android': 'Android',
      },
      'beacon': 'beacon',
      'applicationStatus': 'Application Status',
      'android': {
        'android': 'Android',
        'SysTemp': 'Systems Temp',
        'FanOutput': 'Fan output',
      },
      'DiskSpace': {
        'DiskSpace': 'Disk space',
        'camera': 'Camera',
        'android': 'Android',
      },
      'statusCode': 'status code',
      'currTimeDiff': 'Current time diff',
      'hdmiConnection': 'Hdmi connection',
      'hdmiConnectionStatusConnected': 'Connected',
      'hdmiConnectionStatusDisconnected': 'Disconnected',
      'cameraUsbConnection': 'Camera usb connection',
      'cameraUsbConnectionStatusConnected': 'Connected',
      'cameraUsbConnectionStatusDisconnected': 'Disconnected',
    },
    'img-info': {
      'img-title': 'Photo',
      'modal-close': 'Close',
      'thumbnail-img': 'Thumbnail image of',
      'original-img': 'Original image',
    },
    'live-view': {
      'live-view-title': 'Live view',
      'start-button': 'Start streaming',
      'stop-button': 'Stop streaming',
    },
    'device': {
      'device-title': 'Device',
      'device-id': 'Device ID',
      'region': 'Region',
    },
  },
  'status': {
    'planned': 'Planned',
    'ongoing': 'Ongoing',
    'finished': 'Finished',
    'abondon': 'Abondon',
  },
}
