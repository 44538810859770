export default class MissedlocationInfo {
  constructor(
    timestamp = undefined
    , gpsLocationAltitude = undefined
    , gpsLocationLat = undefined
    , gpsLocationLng = undefined
    , rtkLocationAltitude = undefined
    , rtkLocationLat = undefined
    , rtkLocationLng = undefined
    , rtkOrGpsValue = undefined
    , polylineLocationAltitude = undefined
    , polylineLocationLat = undefined
    , polylineLocationLng = undefined
  ) {
    this.timestamp = timestamp
    this.gpsLocationAltitude = gpsLocationAltitude
    this.gpsLocationLat = gpsLocationLat
    this.gpsLocationLng = gpsLocationLng
    this.rtkLocationAltitude = rtkLocationAltitude
    this.rtkLocationLat = rtkLocationLat
    this.rtkLocationLng = rtkLocationLng
    this.rtkOrGpsValue = rtkOrGpsValue
    this.polylineLocationAltitude = polylineLocationAltitude
    this.polylineLocationLat = polylineLocationLat
    this.polylineLocationLng = polylineLocationLng
  }
}
