export default class MapPosition {
  /**
   * コンストラクタ
   * @param {number} lat 緯度
   * @param {number} lng 経度
   * @param {number} altitude 高度
   */
  constructor(
    lat = 0
    , lng = 0
    , altitude = 0
  ) {
    this.lat = lat
    this.lng = lng
    this.altitude = altitude
  }
}
