import AuthInfo from '@/models/auth-info'
import repository from '@/repositories/rest-api/repository'
import { getAuthenticateInfo, removeAuthenticateInfo, removeUserInfo, setAuthenticateInfo, setUserInfo } from '@/repositories/browser-data/repository'
import AuthenticateInfo from '@/models/auth/authenticate-info'
import UserInfo from '@/models/auth/user-info'

export const BASE_URL = '/api/v1'

export const toAuthenticateInfoModel = (response) => {
  const ACCESS_TOKEN = response.data.body.access_token;
  const REFRESH_TOKEN = response.data.body.refresh_token;
  const EXPIRES_IN = response.data.body.expires_in;
  return new AuthenticateInfo(ACCESS_TOKEN, REFRESH_TOKEN, EXPIRES_IN);
}

/**
 * ブラウザにユーザー情報をセットし、取得情報をモデルにセットして返却
 * @param {import('axios').AxiosResponse} response 取得APIのレスポンス
 * @return {AuthInfo} 認証情報モデル
 */
export const setUserMe = (response) => {
  const userInfo = new UserInfo(
    response.data.body.tenant_id,
    response.data.body.company_id,
    response.data.body.user_id,
    response.data.body.role
  )
  setUserInfo(userInfo)

  return new AuthInfo({
    userId: response.data.body.user_id,
    userName: response.data.body.user_name,
    tenantId: response.data.body.tenant_id,
    companyId: response.data.body.company_id,
    role: response.data.body.role,
    s3Bucket: response.data.body.s3_bucket,
    websocketEndpoints: response.data.body.websocket_endpoints,
  })
}

export const getAuthenticateHeader = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getAuthenticateInfo().accessToken,
  }
}

export const TOKEN_REFRESH_URL = `${BASE_URL}/token:refresh`

export const postTokenRefresh = async () => {
  try {
    const tokenRefreshRequest = {
      refresh_token: getAuthenticateInfo().refreshToken,
    }
    const response = await repository.post(TOKEN_REFRESH_URL, tokenRefreshRequest)
    setAuthenticateInfo(toAuthenticateInfoModel(response))
    return response
  } catch(error) {
    // トークン更新に失敗したら、ブラウザの認証情報を削除しておく
    removeAuthenticateInfo()
    removeUserInfo()
    return Promise.reject(error)
  }
}
