import admin from "./admin-repository"

export default function admins(basePath, userId) {
  const adminRepository = userId ? new admin(`${basePath}/${userId}`) : new admin(`${basePath}`)
  return {
    getList: adminRepository.getList(),
    post: adminRepository.post(),
    put: adminRepository.put(),
    delete: adminRepository.delete(),
  }
}
