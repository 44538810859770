export default {
  'site-title': 'Drone Data Cloud',
  'headline': 'メニュー',
  'home': 'ホーム',
  'login': 'ログイン',
  'mission-list': 'ミッション一覧',
  'password-update': 'アカウント設定',
  'device-list': 'デバイス一覧',
  'drone-user': 'ドローンパイロット一覧',
  'company-list': 'カンパニー一覧',
  'company-admin-list': 'カンパニー管理者一覧',
  'external-bucket': '外部バケット',
  'open-source-license': 'オープンソースライセンス',
  'logout': 'ログアウト',
  'confirm-header': '確認',
  'confirm-logout': 'ログアウトしますか？',
  'ok': 'OK',
  'cancel': 'キャンセル',
  'tenant-update': 'テナントユーザー編集',
}
