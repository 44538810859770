import PoleInfo from '@/models/best-shot/pole-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelPoleInfo = (poleInfo) => {
  return new PoleInfo(
    poleInfo['group_id']
    , poleInfo['latitude']
    , poleInfo['longitude']
  )
}

const toModelPoleInfos = (poleInfos) => {
  let modelPoleInfos = []

  poleInfos.forEach( function(poleInfo) {
    modelPoleInfos.push(toModelPoleInfo(poleInfo))
  })

  return modelPoleInfos
}

const getList = (page, angle) => {
  return async () => {
    try {
      const params = {
        'angle': angle,
        'page_size': 500,       // 最大でも200～300ポール分の想定なので、500を指定して全件取得
        'page_num': page,       // ページ番号指定のロジックは残しておく（呼出し元で1固定で呼び出される）
        'ascending': false,     // 最新順で固定なので、ここで直接指定
      }
      const response = await repository.get(`${baseUrl}`, {params})
      return toModelPoleInfos(response.data.body.groups)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function pole(basePath) {
  baseUrl = basePath
}

pole.prototype.getList = getList
