import CompanyInfo from '@/models/company-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelCompanyInfo = ({
  parentId,
  id,
  company_name,
  street_address,
  city,
  state,
  zip,
  country_code,
}) => {
  return new CompanyInfo({
    parentId: parentId,
    id: id,
    companyName: company_name,
    streetAddress: street_address,
    city: city,
    state: state,
    zip: zip,
    countryCode: country_code,
  })
}

const toModelCompanyInfos = (companyInfos) => {
  let modelCompanyInfos = []

  companyInfos.forEach( function(companyInfo) {
    modelCompanyInfos.push(toModelCompanyInfo(companyInfo))
  })

  return modelCompanyInfos
}

const getList = () => {
  return async (userId) => {
    try {
      const response = await repository.get(`${baseUrl}?user_id=${userId}`)
      return toModelCompanyInfos(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const post = () => {
  return async (userId, companyInfo) => {
    try {
      const params = companyInfo instanceof CompanyInfo
                        ? {
                            'user_id': userId,
                            'company_name': companyInfo.companyName,
                            'street_address': companyInfo.streetAddress,
                            'city': companyInfo.city,
                            'state': companyInfo.state,
                            'country_code': companyInfo.countryCode,
                            'zip': companyInfo.zip,
                          }
                        : undefined
      const response = await repository.post(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const put = () => {
  return async (userId, companyInfo) => {
    try {
      const params = companyInfo instanceof CompanyInfo
                        ? {
                            'user_id': userId,
                            'company_name': companyInfo.companyName,
                            'street_address': companyInfo.streetAddress,
                            'city': companyInfo.city,
                            'state': companyInfo.state,
                            'country_code': companyInfo.countryCode,
                            'zip': companyInfo.zip,
                          }
                        : undefined
      const response = await repository.put(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function company(basePath) {
  baseUrl = basePath
}

company.prototype.getList = getList
company.prototype.post = post
company.prototype.put = put
