import adminRepository from './admin-repository'
import companies from './companies/repository-factory'

const basePath = `/api/v1/tenants`

export default function tenants(tenantId) {
  return {
    admins: adminRepository(`${basePath}/${tenantId}/admins`),
    companies: (companyId) => companies(`${basePath}/${tenantId}/companies`, companyId),
  }
}
