import CompanyAdminInfo from '@/models/company-admin-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelCompanyAdminInfo = (apiResponseBody) => {
  return new CompanyAdminInfo(
    apiResponseBody.id
    , apiResponseBody.status
    , apiResponseBody.created
    , apiResponseBody.first_name
    , apiResponseBody.last_name
    , apiResponseBody.middle_name
    , apiResponseBody.email
    , apiResponseBody.multi_factor_auth
    , apiResponseBody.role
  )
}

const toModelCompanyAdminInfos = (companyAdminInfos) => {
  let modelCompanyAdminInfos = []

  companyAdminInfos.forEach( function(companyAdminInfo) {
    modelCompanyAdminInfos.push(toModelCompanyAdminInfo(companyAdminInfo))
  })

  return modelCompanyAdminInfos
}

/**
 * カンパニーアドミン一覧取得
 * @param {string} tenantAdminUserId テナントアドミンユーザーID
 */
const getList = () => {
  return async (tenantAdminUserId) => {
    const params = {
      'user_id': tenantAdminUserId,
    }
    try {
      const response = await repository.get(`${baseUrl}`, {params})
      return toModelCompanyAdminInfos(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

/**
 * カンパニーアドミン作成
 * @param {string} tenantAdminUserId テナントアドミンユーザーID
 * @param {CompanyAdminInfo} companyAdminInfo 作成するカンパニーアドミン情報
 */
const post = () => {
  return async (tenantAdminUserId, companyAdminInfo) => {
    const params = companyAdminInfo instanceof CompanyAdminInfo
                      ? {
                          'first_name': companyAdminInfo.firstName,
                          'last_name': companyAdminInfo.lastName,
                          'middle_name': companyAdminInfo.middleName,
                          'email': companyAdminInfo.email,
                          'multi_factor_auth': companyAdminInfo.multiFactorAuth,
                        }
                      : undefined
    try {
      const response = await repository.post(`${baseUrl}?user_id=${tenantAdminUserId}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

/**
 * カンパニーアドミン更新
 * @param {CompanyAdminInfo} companyAdminInfo 作成するカンパニーアドミン情報
 */
const put = () => {
  return async (companyAdminInfo) => {
    const params = companyAdminInfo instanceof CompanyAdminInfo
                      ? {
                          'multi_factor_auth': companyAdminInfo.multiFactorAuth,
                        }
                      : undefined
    try {
      const response = await repository.put(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

/**
 * カンパニーアドミン削除
 */
const deleteMethod = () => {
  return async () => {
    try {
      const response = await repository.delete(`${baseUrl}`)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function admin(basePath) {
  baseUrl = basePath
}

admin.prototype.getList = getList
admin.prototype.post = post
admin.prototype.put = put
admin.prototype.delete = deleteMethod
