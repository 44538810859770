import { convertBooleanOfString } from '@/utils/string'

export default class TenantInfo {
  constructor(
    tenantId = undefined
    , activateStatus = undefined
    , created = undefined
    , firstName = undefined
    , lastName = undefined
    , middleName = undefined
    , email = undefined
    , multiFactorAuth = null
    , role = undefined
  ) {
    this.tenantId = tenantId
    this.activateStatus = activateStatus
    this.created = created
    this.firstName = firstName
    this.lastName = lastName
    this.middleName = middleName
    this.email = email
    this.setMultiFactorAuth(multiFactorAuth)
    this.role = role
  }

  fullName() {
    return (this.firstName + ' ' + this.middleName + ' ' + this.lastName).replace('  ', ' ')
  }

  setMultiFactorAuth(multiFactorAuth) {
    this.multiFactorAuth = convertBooleanOfString(multiFactorAuth)
  }
}
