export default {
  'list-title-drone': 'ドローンパイロットユーザー一覧',
  'detail-title-drone': 'ドローンパイロットユーザー一覧',
  'create-title-drone': 'ドローンパイロットユーザー作成',
  'update-title-drone': 'ドローンパイロットユーザー編集',
  'update-title-tenant': 'テナントユーザー編集',
  'create-button': '作成',
  'update-button': '更新',
  'edit-button': '編集',
  'delete-button': '削除',
  'cancel-button': 'キャンセル',
  'detail': {
    'first-name': '名',
    'last-name': '姓',
    'middle-name': 'ミドルネーム',
  },
  'user-info': {
    'name': '名前',
    'email': 'メール',
    'multi-factor-auth': '認証',
    'multi-factor-auth-true': '有効',
    'multi-factor-auth-false': '無効',
    'created': '作成日',
    'activate-status': 'アクティベートステータス',
  },
  'select-users': {
    'company': 'カンパニー',
    'company-admin': 'カンパニー管理者',
  },
}
