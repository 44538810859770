export default {
  'mission': 'missions',
  'user': 'users',
  'delete-confirmation-title': 'Confirm deletion',
  'delete-confirmation-text': 'Deleted %{subject} cannot be undone, do you want to delete them?',
  'required': 'equired',
  'latest': 'Latest',
  'previous': 'Previous',
  'lat': 'lat',
  'lng': 'lng',
}
