import admins from './admins/repository-factory'
import company from './company-repository'
import missions from './missions/repository-factory'
import pilots from './pilots/repository-factory'

export default function companies(basePath, companyId) {
  const companyRepository = companyId ? new company(`${basePath}/${companyId}`) : new company(`${basePath}`)
  return {
    getList: companyRepository.getList(),
    post: companyRepository.post(),
    put: companyRepository.put(),
    admins: (userId) => admins(`${basePath}/${companyId}/admins`, userId),
    missions: (missionId) => missions(`${basePath}/${companyId}/missions`, missionId),
    pilots: (userId) => pilots(`${basePath}/${companyId}/pilots`, userId),
  }
}
