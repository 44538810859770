import UpdateFileUploadStatus from '@/models/websocket/update-file-upload-status'
import UpdateMissionStatus from '@/models/websocket/update-mission-status'

const PLANNED = 0
const ONGOING = 1
const FINISHED = 2
const ABONDON = 3

export default class MissionInfo {
  /**
   * コンストラクタ
   * @param {string} missionId ミッションID
   * @param {string} missionName ミッション名
   * @param {string} pilotId パイロットID
   * @param {string} missionDate ミッション日時
   * @param {any} target フロントエンドでは未使用
   * @param {number} locationLat 緯度
   * @param {number} locationLng 経度
   * @param {number} status ステータス（0:Planned、1:Ongoing、2:Finished、3:Abondon）
   * @param {number} totalNumber 
   * @param {number} uploadedNumber 
   * @param {boolean} isHidden 表示対象である
   */
  constructor(
    missionId = undefined
    , missionName = ''
    , pilotId = undefined
    , missionDate = undefined
    , target = undefined
    , locationLat = undefined
    , locationLng = undefined
    , status = PLANNED
    , totalNumber = 0
    , uploadedNumber = 0
    , isHidden = false
  ) {
    this.missionId = missionId
    this.missionName = missionName
    this.pilotId = pilotId
    this.setMissionDate(missionDate)
    this.target = target
    this.setLocationLat(locationLat)
    this.setLocationLng(locationLng)
    this.setStatus(status)
    this.totalNumber = totalNumber
    this.uploadedNumber = uploadedNumber
    this.isHidden = isHidden
  }

  setMissionName(missionName) {
    this.missionName = missionName
  }

  setMissionDate(missionDate) {
    if (missionDate) {
      this.missionDate = missionDate
    } else {
      this.missionDate = undefined
    }
  }

  setPilotId(pilotId) {
    this.pilotId = pilotId
  }

  setLocationLat(locationLat) {
    this.locationLat = locationLat
  }

  setLocationLng(locationLng) {
    this.locationLng = locationLng
  }

  /**
   * ミッションステータスコードを設定
   * @param {number} status ミッションステータスコード
   */
  setStatus(status) {
    const CHECK_MISSION_CODE = Number(status)
    // ミッションステータスとして想定されるものはそのまま設定し、想定されないものはPlannedとみなす（ステータスが追加された場合は、ここを修正）
    switch (CHECK_MISSION_CODE) {
      case PLANNED:
      case ONGOING:
      case FINISHED:
      case ABONDON:
        this.status = CHECK_MISSION_CODE
        break
      default:
        this.status = PLANNED
    }
  }

  setUpdateFileUploadStatus(updateFileUploadStatus) {
    if (updateFileUploadStatus instanceof UpdateFileUploadStatus) {
      this.totalNumber = updateFileUploadStatus.totalNumber
      this.uploadedNumber = updateFileUploadStatus.uploadedNumber
    }
  }

  setUpdateMissionStatus(updateMissionStatus) {
    if (updateMissionStatus instanceof UpdateMissionStatus) {
      this.setMissionDate(updateMissionStatus.missionDate)
      this.target = updateMissionStatus.target
      this.setLocationLat(updateMissionStatus.locationLat)
      this.setLocationLng(updateMissionStatus.locationLng)
      this.setStatus(updateMissionStatus.status)
    }
  }

  isPlanned() {
    if (this.status === PLANNED) {
      return true
    } else {
      return false
    }
  }

  isOngoing() {
    if (this.status === ONGOING) {
      return true
    } else {
      return false
    }
  }

  isFinished() {
    if (this.status === FINISHED) {
      return true
    } else {
      return false
    }
  }

  isAbondon() {
    if (this.status === ABONDON) {
      return true
    } else {
      return false
    }
  }

  /**
   * ミッションステータスの翻訳用キー名取得
   * ※コード値が不明だったり、想定外の場合は、Plannedとみなす
   * @return {string} ミッションステータスの翻訳用のキー名
   */
  getStatusI18nKey() {
    switch (this.status) {
      case 0:
        return 'message.mission.status.planned'
      case 1:
        return 'message.mission.status.ongoing'
      case 2:
        return 'message.mission.status.finished'
      case 3:
        return 'message.mission.status.abondon'
      default:
        return 'message.mission.status.planned'
    }
  }
}
