import axios from 'axios'
import ErrorInfo from '@/models/error-info'
import { getAuthenticateHeader, postTokenRefresh } from './interceptors/auth'
import { getAuthenticateInfo } from '@/repositories/browser-data/repository'

const baseURL = process.env.VUE_APP_API_BASE_URL

axios.defaults.withCredentials = true

const api = axios.create({
  baseURL,
})

const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) )

const toErrorInfo = (error) => {
  // エラーの場合、レスポンスが返ってこないパターンも考慮して、レスポンスがなければタイムアウトしたものとみなす
  const response = error.response ? error.response : {status: 504, statusText: 'Timeout'}

  return new ErrorInfo(
    error.code
    , error.message
    , response.status
    , response.statusText
    , response.data['error_code']
  )
}

/**
 * トークンリフレッシュへのリクエストでないかチェック
 * @param {string} url 対象アドレス
 * @return トークンリフレッシュ以外の場合：true、トークンリフレッシュの場合：false
 */
const isNotTokenRefreshRequest = (url) => {
  return url.indexOf('token:refresh') === -1
}

api.interceptors.response.use( response => {
  // API正常レスポンス共通処理（正常レスポンスはリクエストごとに違うレスポンスオブジェクトなので、そのまま返す）
  return response
}, error => {
  // APIエラーレスポンス共通処理
  if (error.config && error.response && error.response.status === 401 && isNotTokenRefreshRequest(error.config.url)) {
    // 認証エラーの場合は、ロックをしてトークンをリフレッシュし元のリクエストをリトライする。エラーの場合は、そのまま返す
    return navigator.locks.request('TokenRefresh', async () => {
      return postTokenRefresh().then( () => {
        return api.request(error.config)
      }).catch( (err) => {
        return Promise.reject(err)
      })
    })
  } else {
    // エラーレスポンスは同じレスポンスオブジェクトなので、モデルに詰め替えて返す）
    console.error(error)
    return Promise.reject(toErrorInfo(error))
  }
})

const checkLock = async () => {
  // 現状トークンリフレッシュでしかロックをしないので、リフレッシュ中はロックがなくなるまで1秒間隔で確認しながら待機
  let lockCount = 0
  do {
    const state = await navigator.locks.query()
    lockCount = state.held.length + state.pending.length
    if (lockCount > 0) await sleep(1000)
  } while (lockCount > 0 )
}

// APIリクエスト共通処理
api.interceptors.request.use( config => {
  // リクエストが送信される前の処理

  // ロック状態チェック
  checkLock()

  // ログイン後に呼ぶAPI（ブラウザにアクセストークンがある場合に呼ぶAPI）はヘッダーにアクセストークンをつける
  if (getAuthenticateInfo().isAccessToken()) {
    config.headers = getAuthenticateHeader()
  }

  return config
}, error => {
  // リクエスト エラーの処理
  return Promise.reject(error)
})

export default api
