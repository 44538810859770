import { analysisLocation } from '@/utils/location'
import MissedlocationInfo from '@/models/missed-location-info'

// 共通のはそのままでは使えないので、ここで定義（フォーマットが違うので共通では使えない）
const checkRtkOrGps = (rtk, gps) => {
  if (!rtk.every(item => item === "") && rtk[1] && rtk[2]) {
    return { rtkOrGpsValue: 'RTK', polylineLocationAltitude: rtk[0], polylineLocationLat: rtk[2], polylineLocationLng: rtk[1] }
  } else {
    return { rtkOrGpsValue: 'GPS', polylineLocationAltitude: gps[0], polylineLocationLat: gps[2], polylineLocationLng: gps[1] }
  }
}

export default function (jsonParams) {
  const gpsLocation = analysisLocation(jsonParams['gps_location'])
  const rtkLocation = analysisLocation(jsonParams['rtk_location'])
  const rtkOrGpsInfo = checkRtkOrGps(rtkLocation, gpsLocation)

  return new MissedlocationInfo(
    jsonParams['timestamp']
    , gpsLocation[0]
    , gpsLocation[2]
    , gpsLocation[1]
    , rtkLocation[0]
    , rtkLocation[2]
    , rtkLocation[1]
    , rtkOrGpsInfo.rtkOrGpsValue
    , rtkOrGpsInfo.polylineLocationAltitude
    , rtkOrGpsInfo.polylineLocationLat
    , rtkOrGpsInfo.polylineLocationLng
  )
}
