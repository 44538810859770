export default class PoleInfo {
  /**
   * コンストラクタ
   * @param {string | undefined} poleId ポールID
   * @param {number | undefined} lat 緯度
   * @param {number | undefined} lng 経度
   * @param {Date | undefined} beginTimestamp 撮影開始日時
   * @param {Date | undefined} endTimestamp 撮影終了日時
   * @param {number | undefined} totalImageCount 合計撮影数
   * @param {number | undefined} angle アングル
   * @param {Array.<import('@/models/best-shot/image-info').default> | undefined} imageInfoList 画像情報リスト
   */
  constructor(
    poleId = undefined
    , lat = undefined
    , lng = undefined
    , beginTimestamp = undefined
    , endTimestamp = undefined
    , totalImageCount = undefined
    , angle = undefined
    , imageInfoList = []
  ) {
    this.poleId = poleId
    this.lat = lat
    this.lng = lng
    this.beginTimestamp = beginTimestamp
    this.endTimestamp = endTimestamp
    this.totalImageCount = totalImageCount
    this.setAngle(angle)
    this.imageInfoList = imageInfoList
  }

  setAngle(value) {
    const angle = Number(value)
    if (isNaN(angle)) {
      this.angle = undefined
    } else {
      this.angle = angle
    }
  }
}
