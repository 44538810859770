import awsAuthorizeRepository from './aws-authorize-repository'
import authRepository from './auth-repository'
import bucketsRepository from './buckets-repository'
import tenantsBucketsRepository from './tenants/bucket-repository'
import tenantsRepositoryFactory from './tenants/repository-factory'

export default {
  aws: awsAuthorizeRepository,
  auth: authRepository,
  buckets: bucketsRepository,
  tenantsBuckets: tenantsBucketsRepository,
  tenants: tenantsRepositoryFactory,
}
