import originalImage from './original-image-repository'
import exif from './exif-repository'

export default function images(basePath, imageId) {
  const encodeImageId = encodeURIComponent(imageId)
  return {
    originalImage: originalImage(`${basePath}/${encodeImageId}/original_image`),
    exif: exif(`${basePath}/${encodeImageId}/detail_meta`),
  }
}
