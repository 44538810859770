export const HDMI_CONNECTION_STATUS_DISCONNECTED = 0
export const HDMI_CONNECTION_STATUS_CONNECTED = 1

export const CAMERA_USB_CONNECTION_STATUS_DISCONNECTED = 0
export const CAMERA_USB_CONNECTION_STATUS_CONNECTED = 1

export default class DeviceExtensionInfo {
  constructor(deviceExtensionInfo) {
    this.hdmiConnection = this.analysisResultHdmiConnection(deviceExtensionInfo['hdmi_connection'])
    this.cameraUsbConnections = this.analysisResultCameraUsbConnections(deviceExtensionInfo['camera_usb_connection'])
  }

  analysisResultHdmiConnection(hdmiConnection) {
    const status = hdmiConnection ? hdmiConnection['status'] ? hdmiConnection['status'] : HDMI_CONNECTION_STATUS_DISCONNECTED : HDMI_CONNECTION_STATUS_DISCONNECTED

    return {
      status,
    }
  }

  /**
   * カメラUSB接続情報解析
   * @param {Array.<object>} cameraUsbConnections カメラUSB接続情報リスト
   * @return カメラUSB接続情報モデルリスト
   */
  analysisResultCameraUsbConnections(cameraUsbConnections) {
    const cameraUsbConnectionModelList = []

    if (cameraUsbConnections && cameraUsbConnections.length > 0) {
      cameraUsbConnections.forEach((cameraUsbConnection) =>{
        // camera_numberには数値に変換可能な値が入っている想定で、数値に変換できない値の場合は無効なデータとみなして次のアイテムの処理に進む
        if (cameraUsbConnection && isNaN(cameraUsbConnection['camera_number'])) return

        const cameraNumber = Number(cameraUsbConnection['camera_number'])
        const status = cameraUsbConnection['status'] ? Number(cameraUsbConnection['status']) : CAMERA_USB_CONNECTION_STATUS_DISCONNECTED
        const cameraUsbConnectionModel = {
          cameraNumber,
          status,
        }

        cameraUsbConnectionModelList.push(cameraUsbConnectionModel)
      })
    }

    return cameraUsbConnectionModelList
  }
}
