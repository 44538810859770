export const formatRtk = (rtkCoordinates) => {
  return rtkCoordinates
}

/**
 * バックエンドフォーマットの位置情報解析
 * @param {string} location バックエンドから取得した位置情報フォーマット
 * @return コロン区切りで、既存に倣って0番目は高度、1番目がlat、2番目がlng
 */
export const analysisLocation = (location) => {
  return location ? location.split(':').map(Number) : ['', '', '']
}

export const checkRtkOrGps = (rtk, gps) => {
  // RTKが存在する場合はRTKを使い、存在しない場合はGPSを使う
  if (!rtk.every(item => item === "") && rtk[1] && rtk[2]) {
    return { rtkOrGpsValue: 'RTK', polylineLocationAltitude: formatRtk(rtk[0]), polylineLocationLat: formatRtk(rtk[1]), polylineLocationLng: formatRtk(rtk[2]) }
  } else {
    return { rtkOrGpsValue: 'GPS', polylineLocationAltitude: gps[0], polylineLocationLat: gps[1], polylineLocationLng: gps[2] }
  }
}
