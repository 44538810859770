export default {
  'exif-title': 'Image metadata',

  // イメージ
  'exif-title-image': 'Image',
  'pixel-x-dimension': 'Pixel X Dimension',
  'pixel-y-dimension': 'Pixel Y Dimension',
  'x-resolution': 'X Resolution',
  'y-resolution': 'Y Resolution',
  'resolution-unit': 'Resolution Unit',
  'color-space': 'Color Space',
  'compressed-bits-per-pixel': 'Compressed Bits Per Pixel',

  // カメラ
  'exif-title-camera': 'Camera',
  'make': 'Make',
  'model': 'Model',
  'max-aperture-value': 'Max Aperture Value',
  'focal-length': 'Focal Length',
  'focal-length-in-35mm-film': 'Focal Length In 35mm Film',
  'shutter-speed': 'Shutter Speed',
  'f-number': 'F Number',
  'exposure-bias-value': 'Exposure Bias Value',
  'exposure-mode': 'Exposure Mode',
  'exposure-time': 'Exposure Time',
  'iso-speed-ratings': 'ISO Speed Ratings',
  'metering-mode': 'Metering Mode',
  'scene-capture-type': 'Scene Capture Type',

  // Exif
  'exif-title-exif': 'Exif',
  'flash': 'Flash',
  'flashpix-version': 'Flashpix Version',
  'contrast': 'Contrast',
  'light-source': 'Light Source',
  'exposure-program': 'Exposure Program',
  'saturation': 'Saturation',
  'sharpness': 'Sharpness',
  'brightness-value': 'Brightness Value',
  'white-balance': 'White Balance',
  'digital-zoom-ratio': 'Digital Zoom Ratio',
  'scene-type': 'Scene Type',
  'custom-rendered': 'Custom Rendered',
  'components-configuration': 'Components Configuration',
  'exif-ifd-pointer': 'Exif IFD Pointer',
  'ycbcr-positioning': 'YCbCr Positioning',
  'file-source': 'File Source',
  'user-comment': 'User Comment',
  'exif-version': 'Exif Version',

  // GPS
  'exif-title-gps': 'GPS',
  'latitude': 'Latitude',
  'longitude': 'Longitude',
  'altitude': 'Altitude',
}
