/**
 * フロントエンドでは共通のフォーマットで日時を表示する想定なので、ここでフォーマットして返却
 * @param {Date} date 対象日時
 * @return フォーマット後の日時文字列
 */
export const formatDateTime = (date, includeSecond) => {
  const dateString = date.toLocaleDateString('sv-SE')
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  if(includeSecond) {
    timeOptions.second = '2-digit'
  }
  const timeString = date.toLocaleTimeString('sv-SE', timeOptions)

  return `${dateString} ${timeString}`
}
