import TenantInfo from '@/models/tenant-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelTenantInfo = (apiResponseBody) => {
  return new TenantInfo(
    apiResponseBody.id
    , apiResponseBody.status
    , apiResponseBody.created
    , apiResponseBody.firstName
    , apiResponseBody.lastName
    , apiResponseBody.middleName
    , apiResponseBody.email
    , apiResponseBody.multiFactorAuth
    , apiResponseBody.role
  )
}

const get = () => {
  return async (userId) => {
    try {
      const response = await repository.get(`${baseUrl}/${userId}`)
      return toModelTenantInfo(response.data.body.tenantUser)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const put = () => {
  return async (userId, tenantInfo) => {
    if (tenantInfo instanceof TenantInfo) {
      const params = {
        'multi_factor_auth': tenantInfo.multiFactorAuth,
      }
      try {
        const response = await repository.put(`${baseUrl}/${userId}`, params)
        return response
      } catch(error) {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject({})
    }
  }
}

export default function admins(basePath) {
  baseUrl = basePath
  return {
    get: get(),
    put: put(),
  }
}
