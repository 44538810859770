export default class ImageMetadata {
  /**
   * コンストラクタ
   * @param {string} imageId イメージID
   * @param {import('@/models/map-position').default} gpsPosition GPS位置情報
   */
  constructor(
    imageId = ''
    , gpsPosition = undefined
  ) {
    this.imageId = imageId
    this.gpsPosition = gpsPosition
  }
}
