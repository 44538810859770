export default {
  'exif-title': '画像のメタデータ',

  // イメージ
  'exif-title-image': 'イメージ',
  'pixel-x-dimension': '画像の幅',
  'pixel-y-dimension': '画像の高さ',
  'x-resolution': '幅の解像度',
  'y-resolution': '高さの解像度',
  'resolution-unit': '解像度の単位',
  'color-space': '色空間情報',
  'compressed-bits-per-pixel': '画像圧縮モード',

  // カメラ
  'exif-title-camera': 'カメラ',
  'make': 'メーカー名',
  'model': 'モデル名',
  'max-aperture-value': 'レンズ最大F値',
  'focal-length': 'レンズ焦点距離',
  'focal-length-in-35mm-film': '35mm換算レンズ焦点距離',
  'shutter-speed': 'シャッタースピード',
  'f-number': 'レンズのF値',
  'exposure-bias-value': '露出補正値',
  'exposure-mode': '露出モード',
  'exposure-time': '露出時間',
  'iso-speed-ratings': 'ISOスピードレート',
  'metering-mode': '測光方式',
  'scene-capture-type': '撮影シーンタイプ',

  // Exif
  'exif-title-exif': 'Exif',
  'flash': 'フラッシュ',
  'flashpix-version': '対応FlashPixバージョン',
  'contrast': 'コントラスト',
  'light-source': '光源',
  'exposure-program': '露出プログラム',
  'saturation': '彩度',
  'sharpness': 'シャープネス',
  'brightness-value': '輝度値',
  'white-balance': 'ホワイトバランス',
  'digital-zoom-ratio': 'デジタルズーム倍率',
  'scene-type': 'シーンタイプ',
  'custom-rendered': 'カスタムレンダリング',
  'components-configuration': '各コンポーネントの意味',
  'exif-ifd-pointer': 'Exif IFD へのポインタ',
  'ycbcr-positioning': 'YCCの画素構成（YとCの位置）',
  'file-source': 'ファイルソース',
  'user-comment': 'ユーザコメント',
  'exif-version': 'Exifバージョン',

  // GPS
  'exif-title-gps': 'GPS',
  'latitude': '緯度',
  'longitude': '経度',
  'altitude': '高度',
}
