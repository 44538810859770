import { authenticate, userMeInfo } from './auth'
import { createRouter, createWebHistory } from 'vue-router'
import { isDroneOrCompanyRoleView, isTenantRoleView, isCompanyOrTenantRoleView, isDeveloperOnlyView } from './role'
import { changePassword } from '@/services/auth'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/CallbackView.vue'),
  },
  {
    path: '/user/me',
    name: 'UserPasswordView',
    beforeEnter: () => {
      changePassword()
    },
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/HomeView.vue'),
    props: userMeInfo,
    children: [
      {
        path: '/',
        name: 'HomeView',
        component: () => import('@/views/HomeContentsView.vue'),
      },
      {
        path: '/licenses',
        name: 'LicensesView',
        component: () => import('@/views/LicensesView.vue'),
      },
      {
        path: '/missions',
        name: 'MissionListView',
        component: () => import('@/views/MissionListView.vue'),
        beforeEnter: (to, from, next) => {
          isDroneOrCompanyRoleView(to, from, next)
        },
      },
      {
        path: '/missions/:missionId',
        name: 'MissionDetailView',
        component: () => import('@/views/MissionDetailView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isDroneOrCompanyRoleView(to, from, next)
        },
      },
      {
        path: '/mission/create',
        name: 'MissionCreateView',
        component: () => import('@/views/MissionCreateView.vue'),
        beforeEnter: (to, from, next) => {
          isDroneOrCompanyRoleView(to, from, next)
        },
      },
      {
        path: '/missions/:missionId/edit',
        name: 'MissionUpdateView',
        component: () => import('@/views/MissionUpdateView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isDroneOrCompanyRoleView(to, from, next)
        },
      },
      {
        path: '/users',
        name: 'DronePilotList',
        component: () => import('@/views/DronePilotList.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isCompanyOrTenantRoleView(to, from, next)
        },
      },
      {
        path: '/users/:usersId',
        name: 'DronePilotDetailView',
        component: () => import('@/views/DronePilotDetailView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isCompanyOrTenantRoleView(to, from, next)
        },
      },
      {
        path: '/users/:usersId/edit',
        name: 'DronePilotUsersUpdateView',
        component: () => import('@/views/DronePilotUsersUpdateView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isCompanyOrTenantRoleView(to, from, next)
        },
      },
      {
        path: '/users/create',
        name: 'DronePilotUsersCreateView',
        component: () => import('@/views/DronePilotUsersCreateView.vue'),
        beforeEnter: (to, from, next) => {
          isCompanyOrTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies',
        name: 'CompaniesListView',
        component: () => import('@/views/CompaniesListView.vue'),
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/:companyId',
        name: 'CompaniesDetailView',
        component: () => import('@/views/CompaniesDetailView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/create',
        name: 'CompaniesCreateView',
        component: () => import('@/views/CompaniesCreateView.vue'),
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/:companyId/edit',
        name: 'CompaniesUpdateView',
        component: () => import('@/views/CompaniesUpdateView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/admins',
        name: 'CompaniesAdminListView',
        component: () => import('@/views/CompaniesAdminListView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/:companyId/admins/:companyAdminId',
        name: 'CompaniesAdminDetailView',
        component: () => import('@/views/CompaniesAdminDetailView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/:companyId/admins/:companyAdminId/edit',
        name: 'CompaniesAdminUpdateView',
        component: () => import('@/views/CompaniesAdminUpdateView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/companies/:companyId/create',
        name: 'CompaniesAdminCreateView',
        component: () => import('@/views/CompaniesAdminCreateView.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/user/edit',
        name: 'TenantUpdateView',
        component: () => import('@/views/TenantUpdateView.vue'),
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/external/bucket',
        name: 'ExternalBucketView',
        component: () => import('@/views/ExternalBucketView.vue'),
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/external/bucket/input',
        name: 'ExternalBucketInputView',
        component: () => import('@/views/ExternalBucketInputView.vue'),
        beforeEnter: (to, from, next) => {
          isTenantRoleView(to, from, next)
        },
      },
      {
        path: '/styleguide',
        name: 'StyleGuide',
        component: () => import('@/views/style-guide/StyleGuide.vue'),
        beforeEnter: (to, from, next) => {
          isDeveloperOnlyView(to, from, next)
        },
      },
    ],
  },
  {
    // 上記のどのパスにもマッチしなかった場合、トップページにリダイレクト
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

// グローバル before ガード。処理はルート設定内の beforeEnter よりも前に実行される。認証関連の制御を行う想定
router.beforeEach((to, from, next) => {
  authenticate(to, from, next)
})

export default router
