import images from './images/repository-factory'
import pole from './pole-repository'

export default function bestshots(basePath, poleInfo, page, angle) {
  // bestshot_groupsかbestshotsのどちらかに統一してほしいと思いつつ、フロントエンドではpoleのイメージ
  const poleRepository = new pole(`${basePath}/bestshot_groups`)
  const poleId = poleInfo ? poleInfo.poleId : ''
  return {
    getList: poleRepository.getList(page, angle),
    images: images(`${basePath}/bestshots/${poleId}/images`, undefined, poleInfo),
  }
}
