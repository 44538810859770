import FlightStatus from '@/models/flight-status'
import MissionInfo from '@/models/mission-info'
import repository from '@/repositories/rest-api/repository'
import { analysisLocation, checkRtkOrGps, formatRtk } from '@/utils/location'
import toMissedLocationInfo from '@/models/websocket/message/receive/to-missed-location-info'

let baseUrl = ''

const toModelMissionInfo = (missionInfo) => {
  let locationLat = undefined
  let locationLng = undefined
  if (missionInfo.place) {
    if (missionInfo.place[0]) locationLat = missionInfo.place[0]  // 値が空以外の場合取得
    if (missionInfo.place[1]) locationLng = missionInfo.place[1]  // 値が空以外の場合取得
  }
  return new MissionInfo(
    missionInfo.mission_id
    , missionInfo.name
    , missionInfo.pilot_id
    , missionInfo.date
    , missionInfo.target
    , locationLat
    , locationLng
    , missionInfo.status
    , missionInfo.total_number
    , missionInfo.uploaded_number
    , !missionInfo.display
  )
}

const toModelMissionInfos = (missionInfos) => {
  let modelMissionInfos = []

  missionInfos.forEach( function(missionInfo) {
    modelMissionInfos.push(toModelMissionInfo(missionInfo))
  })

  return modelMissionInfos
}

// バックエンドでのデータの持ち方を見る限り、ミッション情報の一部としてのフライト情報というイメージみたいなので、
// 使用していなかったID類は削除（ミッションIDを主キーとしているようなので、ミッションIDは持つようにしておく）
// 利用するタイミングが出た場合にモデルに追加してここで設定する想定
const toModelFlightStatus = (flightStatus) => {
  // flightStatusがnullの時は、モデルで指定しているデフォルト値で初期化したものを返却
  if (!flightStatus) return new FlightStatus()

  // locationは文字列でコロン区切りで、既存に倣って0番目は高度、1番目がlat、2番目がlngの想定
  const gpsLocation = analysisLocation(flightStatus['gps_location'])
  const rtkLocation = analysisLocation(flightStatus['rtk_location'])
  const rtkOrGpsInfo = checkRtkOrGps(rtkLocation, gpsLocation)

  return new FlightStatus(
    flightStatus.mission_id
    , flightStatus.timestamp
    , gpsLocation[0]
    , gpsLocation[1]
    , gpsLocation[2]
    , formatRtk(rtkLocation[0])
    , formatRtk(rtkLocation[1])
    , formatRtk(rtkLocation[2])
    , rtkOrGpsInfo.rtkOrGpsValue
    , rtkOrGpsInfo.polylineLocationAltitude
    , rtkOrGpsInfo.polylineLocationLat
    , rtkOrGpsInfo.polylineLocationLng
    , flightStatus.flight_time
    , flightStatus.android_battery
    , flightStatus.application_status
    , flightStatus.android_sys_temp
    , flightStatus.android_disk_space
    , flightStatus['device_extension_info']
  )
}

const getList = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}`)
      return toModelMissionInfos(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const getFlightStatus = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}/status`)
      return toModelFlightStatus(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const toMissedLocationList = (missedLocationList) => {
  let modelMissedLocationList = []

  if(missedLocationList && missedLocationList.length > 0) {
    missedLocationList.forEach(missedLocation => {
      // WebSocket用のものをRestAPIで使ってしまっているので注意（必要に応じて修正したい）
      modelMissedLocationList.push(toMissedLocationInfo(missedLocation))
    })
  }

  return modelMissedLocationList
}

const getMissedLocation = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}/missed_locations`)
      return toMissedLocationList(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function mission(basePath) {
  baseUrl = basePath
}

mission.prototype.getList = getList
mission.prototype.getFlightStatus = getFlightStatus
mission.prototype.getMissedLocation = getMissedLocation
