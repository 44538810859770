export default {
  'list-title': 'List of devices',
  'create-title': 'Create device',
  'edit-title': 'Device edit/delete',
  'detail-title': 'Device details',
  'camera-title': 'Camera settings',
  'create-button': 'Create',
  'update-button': 'Update',
  'edit-button': 'Edit',
  'delete-button': 'Delete',
  'device': {
    'device-name': 'Device name',
    'uuid': 'UUID',
    'date': 'Registration date',
    'online-info': 'Online info',
  },
  'status': {
    'offline': 'Offline',
    'online-camera-on': 'Online and camera connected',
    'online-camera-off': 'Online and camera no connection',
    'unknown': 'Unknown',
  },
}
