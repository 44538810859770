import repositoryFactory from "@/repositories/rest-api/repository-factory"

export const loginUser = () => {
  repositoryFactory.auth.getLoginURL()
}

export const changePassword = () => {
  repositoryFactory.auth.changePassword()
}

export const exchangeCode = (code, state) => {
  return repositoryFactory.auth.exchangeCode(code, state)
}

export const getUserMeInfo = async () => {
  return await repositoryFactory.auth.getUserMeInfo()
}

export const postLogout = () => {
  return repositoryFactory.auth.postLogout()
}
