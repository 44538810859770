// TODO img-infoをリファクタリングしたいが、とりあえずそのまま利用
import ImgInfo from './img-info'

export default class ThumbnailInfo extends ImgInfo {
  /**
   * コンストラクタ
   * @param {string} thumbnailId サムネイルID
   * @param {string} thumbnailUrl サムネイル画像のアドレス
   */
  constructor(thumbnailId, thumbnailUrl) {
    // TODO ImgInfoをリファクタリングしたほうが良いが、一旦そのまま利用（引数をオブジェクトではなく、プリミティブな値のほうが利用しやすい）
    const imgInfo = {
      imgId: thumbnailId,
      imgSrc: thumbnailUrl,
    }
    super(imgInfo)
  }
}