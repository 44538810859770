export default {
  'list-title-drone': 'Drone Pilot List',
  'detail-title-drone': 'Drone Pilot Detail',
  'create-title-drone': 'Drone Pilot Create',
  'update-title-drone': 'Drone Pilot Edit',
  'update-title-tenant': 'Tenant Edit',
  'create-button': 'Create',
  'update-button': 'Update',
  'edit-button': 'Edit',
  'delete-button': 'Delete',
  'cancel-button': 'Cancel',
  'detail': {
    'first-name': 'First name',
    'last-name': 'Last name',
    'middle-name': 'Middle name',
  },
  'user-info': {
    'name': 'Name',
    'email': 'Mail',
    'multi-factor-auth': 'Multi factor auth',
    'multi-factor-auth-true': 'validity',
    'multi-factor-auth-false': 'disabled',
    'created': 'Create date',
    'activate-status': 'Activate status',
  },
  'select-users': {
    'company': 'Company',
    'company-admin': 'Administrator',
  },
}
