import { formatDateTime } from '@/utils/date'

/**
 * 文字列内にある全角を半角へ変換（対象は英数字で、カタカナは変換対象外）
 * @param {string} value 変換したい文字列
 * @return {string} 変換された文字列
 */
export const replaceFullToHalf = (value) => {
  if (!value) return value

  return String(value).replace(/[！-～]/g, function(all) {
    return String.fromCharCode(all.charCodeAt(0) - 0xFEE0)
  })
}

/**
 * 数値を位置情報用文字列に変換
 * @param {number} value 変換したい数値
 * @return {string} 変換された文字列（undefinedやnull、空文字が指定された場合は、undefined）
 */
export const toPlaceValue = (value) => {
  if (value) {
    return String(value)
  } else {
    // undefinedやnull、空文字が指定された場合は、undefined返す
    return undefined
  }
}

/**
* String型の'true'と'false'をBoolean型に変換。それ以外はそのまま返す
* @param {boolean | string} str 変換対象値
* @returns {boolean | string} boolean型のtrueまたはfalse、string: それ以外の文字列
*/
export const convertBooleanOfString = (str) => {
  if (typeof str == 'string' && (str == 'true' || str == 'false')) {
    return str == 'true' ? true : false
  } else {
    return str
  }
}

/**
 * メガバイトからギガバイトに変換
 * @param {string} value 変換元のメガバイト単位の数値
 * @param {number} numberOfDecimalPlaces 小数点以下の表示桁数（未指定の場合、2桁に丸め込む）
 * @return {string} 小数点以下2桁のギガバイト単位のフォーマット（例：12.34 GB）
 */
export const megabyteToGigabyte = (value, numberOfDecimalPlaces = 2) => {
  if (isNaN(value)) {
    return ''
  } else {
    return (parseFloat(value) / 1024).toFixed(numberOfDecimalPlaces) + ' GB'
  }
}

/**
 * UTC時間からタイムゾーンを追加した表示に変換(Z付きの場合)

 * @param {string} updatedDate UTC時間
 * @return {string} タイムゾーンを追加した表示（例：2023-10-10 10:10）
 */
export const convertUtcToLocalTime = (updatedDate) => {
  const date = new Date(updatedDate);
  return formatDateTime(date, false)
}
