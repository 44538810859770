export default class ExifInfo {
  constructor({
    pixelXDimension = undefined
    , pixelYDimension = undefined
    , fileSource = undefined
    , sceneType = undefined
    , customRendered = undefined
    , colorSpace = undefined
    , componentsConfiguration = undefined
    , compressedBitsPerPixel = undefined
    , shutterSpeed = undefined
    , fNumber = undefined
    , brightnessValue = undefined
    , exposureTime = undefined
    , exposureMode = undefined
    , isoSpeedRatings = undefined
    , exposureBiasValue = undefined
    , focalLength = undefined
    , maxApertureValue = undefined
    , meteringMode = undefined
    , flash = undefined
    , flashpixVersion = undefined
    , focalLengthIn35mmFilm = undefined
    , sceneCaptureType = undefined
    , contrast = undefined
    , lightSource = undefined
    , exposureProgram = undefined
    , saturation = undefined
    , sharpness = undefined
    , whiteBalance = undefined
    , digitalZoomRatio = undefined
    , exifIfdPointer = undefined
    , exifVersion = undefined
    , make = undefined
    , model = undefined
    , xResolution = undefined
    , yResolution = undefined
    , resolutionUnit = undefined
    , ycbcrPositioning = undefined
    , userComment = undefined
    , latitude = undefined
    , longitude = undefined
    , altitude = undefined
  }) {
    this.pixelXDimension = pixelXDimension
    this.pixelYDimension = pixelYDimension
    this.fileSource = fileSource
    this.sceneType = sceneType
    this.customRendered = customRendered
    this.colorSpace = colorSpace
    this.componentsConfiguration = componentsConfiguration
    this.compressedBitsPerPixel = compressedBitsPerPixel
    this.shutterSpeed = shutterSpeed
    this.fNumber = fNumber
    this.brightnessValue = brightnessValue
    this.exposureTime = exposureTime
    this.exposureMode = exposureMode
    this.isoSpeedRatings = isoSpeedRatings
    this.exposureBiasValue = exposureBiasValue
    this.focalLength = focalLength
    this.maxApertureValue = maxApertureValue
    this.meteringMode = meteringMode
    this.flash = flash
    this.flashpixVersion = flashpixVersion
    this.focalLengthIn35mmFilm = focalLengthIn35mmFilm
    this.sceneCaptureType = sceneCaptureType
    this.contrast = contrast
    this.lightSource = lightSource
    this.exposureProgram = exposureProgram
    this.saturation = saturation
    this.sharpness = sharpness
    this.whiteBalance = whiteBalance
    this.digitalZoomRatio = digitalZoomRatio
    this.exifIfdPointer = exifIfdPointer
    this.exifVersion = exifVersion
    this.make = make
    this.model = model
    this.xResolution = xResolution
    this.yResolution = yResolution
    this.resolutionUnit = resolutionUnit
    this.ycbcrPositioning = ycbcrPositioning
    this.userComment = userComment
    this.latitude = latitude
    this.longitude = longitude
    this.altitude = altitude
  }
}
