import ImageInfo from '@/models/best-shot/image-info'
import PoleInfo from '@/models/best-shot/pole-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelImageInfo = (imageInfo) => {
  return new ImageInfo(
    imageInfo['image_id']
    , imageInfo['url']
    , imageInfo['score']
    , new Date(imageInfo['timestamp'])
    , imageInfo['latitude']
    , imageInfo['longitude']
    , imageInfo['altitude']
  )
}

const toModelImageInfos = (imageInfos) => {
  let modelImageInfos = []

  imageInfos.forEach( function(imageInfo) {
    modelImageInfos.push(toModelImageInfo(imageInfo))
  })

  return modelImageInfos
}

/**
 * ポール情報モデルへ詰め替え
 * @param {PoleInfo} poleInfo ポール情報モデル
 * @param {Object} poleAndImageInfos APIの返却値
 * @returns 
 */
const toModelPoleInfo = (poleInfo, poleAndImageInfos) => {
  return new PoleInfo(
    poleInfo.poleId
    , poleInfo.lat
    , poleInfo.lng
    , new Date(poleAndImageInfos['timestamp_head'])
    , new Date(poleAndImageInfos['timestamp_tail'])
    , poleAndImageInfos['total_shots']
    , poleInfo.angle
    , toModelImageInfos(poleAndImageInfos['images'])
  )
}

// 一部ポール情報と、画像情報リストが返ってくるイメージなので、ポール情報を引数で受け取って、追加情報を含めたポール情報モデルを作成して返す
const getPoleAndImageList = (poleInfo) => {
  return async () => {
    try {
      const params = {
        'angle': poleInfo.angle,
        'page_size': 5,       // 最大5枚分で固定なので、ここで直接指定
        'page_num': 1,        // 最大5枚分で固定なので、ここで直接指定
        'ascending': false,   // スコアの降順で固定なので、ここで直接指定
      }
      const response = await repository.get(`${baseUrl}`, {params})
      return toModelPoleInfo(poleInfo, response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function image(basePath) {
  baseUrl = basePath
}

image.prototype.getPoleAndImageList = getPoleAndImageList
