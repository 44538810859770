export default class ImageInfo {
  /**
   * コンストラクタ
   * @param {string | undefined} imageId 画像ID
   * @param {string | undefined} imageUrl 画像URL
   * @param {number | undefined} score スコア
   * @param {Date | undefined} timestamp 撮影日時
   * @param {number | undefined} lat 緯度
   * @param {number | undefined} lng 経度
   * @param {number | undefined} altitude 高度
   */
  constructor(
    imageId = undefined
    , imageUrl = undefined
    , score = undefined
    , timestamp = undefined
    , lat = undefined
    , lng = undefined
    , altitude = undefined
  ) {
    this.imageId = imageId
    this.imageUrl = imageUrl
    this.score = score
    this.timestamp = timestamp
    this.lat = lat
    this.lng = lng
    this.altitude = altitude
  }
}
