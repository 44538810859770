import AuthInfo from '@/models/liveview-auth'
import repository from '@/repositories/rest-api/repository'

const toModelAuthInfo = ({
  access_key_id,
  secret_access_key,
  session_token,
}) => {
  return new AuthInfo({
    AccessKeyId: access_key_id,
    SecretAccessKey: secret_access_key,
    SessionToken: session_token,
  })
}

export default {
  getAwsAuthorize(deviceId) {
    const params = {
      'deviceid': deviceId,
    }

    return repository
      .get('/api/v1/authorize', {params})
      .then(function (response) {
        return toModelAuthInfo(response.data.body)
      })
      .catch(function (error) {
        return error
      })
      .finally(function () {})
  },
}
