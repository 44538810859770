export default {
  'list-title': 'Company List',
  'detail-title': 'Company Detail',
  'create-title': 'Company create',
  'edit-title': 'Company Edit',
  'list-admin-title': 'Company Administrator List',
  'detail-admin-title': 'Company Administrator Detail',
  'create-admin-title': 'Company Administrator Create',
  'update-admin-title': 'Company Administrator Edit',
  'create-button': 'Create',
  'update-button': 'Update',
  'edit-button': 'Edit',
  'delete-button': 'Delete',
  'cancel-button': 'Cancel',
  'companies': {
    'company-name': 'Company name',
    'address': 'Address',
    'city': 'City',
    'state': 'State/Province/Region',
    'country': 'Country/Region',
    'zip': 'Zip/Postal Code',
    'date': 'Registration date',
  },
  'error-message': {
    'enter-company-name': 'Please enter your company name',
    'company-name-in-use': 'This company name is already in use',
    'enter-address': 'Please enter your address',
    'enter-city': 'Please enter city',
    'enter-state': 'Please enter your state',
    'enter-country': 'Please enter your country/region',
    'enter-zip': 'Please enter your zip code',
    'create-update-failed': 'Create/update failed',
  },
}
