export default {
  'not-number': '数値で入力してください',
  'out-range': '{min} から {max} の範囲内で入力してください',
  'enter-first-name': '名を入力してください',
  'incorrect-first-name': '名前が正しく入力されていません ( {errorcode} )',
  'enter-last-name': '姓を入力してください',
  'incorrect-last-name': '姓が正しく入力されていません ( {errorcode} )',
  'enter-email': '有効なメールアドレスを入力してください',
  'email-in-use': 'このメールアドレスは既に使用されています ( {errorcode} )',
  'select-mfa': '多要素認証を選択してください',
  'create-update-failed': '作成・更新に失敗しました ( {errorcode} )',
}
