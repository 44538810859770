import ExifInfo from '@/models/exif-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelExifInfo = (apiResponseBody) => {
  return new ExifInfo({
    pixelXDimension: apiResponseBody['pixel_x_dimension'],
    pixelYDimension: apiResponseBody['pixel_y_dimension'],
    fileSource: apiResponseBody['file_source'],
    sceneType: apiResponseBody['scene_type'],
    customRendered: apiResponseBody['custom_rendered'],
    colorSpace: apiResponseBody['color_space'],
    componentsConfiguration: apiResponseBody['components_configuration'],
    compressedBitsPerPixel: apiResponseBody['compressed_bits_per_pixel'],
    shutterSpeed: apiResponseBody['shutter_speed'],
    fNumber: apiResponseBody['f_number'],
    brightnessValue: apiResponseBody['brightness_value'],
    exposureTime: apiResponseBody['exposure_time'],
    exposureMode: apiResponseBody['exposure_mode'],
    isoSpeedRatings: apiResponseBody['iso_speed_ratings'],
    exposureBiasValue: apiResponseBody['exposure_bias_value'],
    focalLength: apiResponseBody['focal_length'],
    maxApertureValue: apiResponseBody['max_aperture_value'],
    meteringMode: apiResponseBody['metering_mode'],
    flash: apiResponseBody['flash'],
    flashpixVersion: apiResponseBody['flashpix_version'],
    focalLengthIn35mmFilm: apiResponseBody['focal_length_in_35mm_film'],
    sceneCaptureType: apiResponseBody['scene_capture_type'],
    contrast: apiResponseBody['contrast'],
    lightSource: apiResponseBody['light_source'],
    exposureProgram: apiResponseBody['exposure_program'],
    saturation: apiResponseBody['saturation'],
    sharpness: apiResponseBody['sharpness'],
    whiteBalance: apiResponseBody['white_balance'],
    digitalZoomRatio: apiResponseBody['digital_zoom_ratio'],
    exifIfdPointer: apiResponseBody['exif_ifd_pointer'],
    exifVersion: apiResponseBody['exif_version'],
    make: apiResponseBody['make'],
    model: apiResponseBody['model'],
    xResolution: apiResponseBody['x_resolution'],
    yResolution: apiResponseBody['y_resolution'],
    resolutionUnit: apiResponseBody['resolution_unit'],
    ycbcrPositioning: apiResponseBody['ycbcr_positioning'],
    userComment: apiResponseBody['user_comment'],
    latitude: apiResponseBody['latitude'],
    longitude: apiResponseBody['longitude'],
    altitude: apiResponseBody['altitude'],
  })
}

const get = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}`)
      return toModelExifInfo(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function exif(basePath) {
  baseUrl = basePath
  return {
    get: get(),
  }
}
