export default class DeviceInfo {
  /**
   * コンストラクタ
   * @param {string} deviceId デバイスID
   * @param {string} region リージョン名
   */
  constructor(
    deviceId = undefined
    , region = undefined
  ) {
    this.deviceId = deviceId
    this.region = region
  }

  isGetDeviceIdFailed() {
    if (this.deviceId === undefined || this.deviceId === null || this.deviceId === '') {
      return true
    } else {
      return false
    }
  }

  isGetDeviceRegionFailed() {
    if (this.region === undefined || this.region === null || this.region === '') {
      return true
    } else {
      return false
    }
  }

  isDeviceError() {
    return this.isGetDeviceIdFailed() || this.isGetDeviceRegionFailed()
  }
}
