export default {
  'user-title': 'User Information',
  'password-title': 'Update password',
  'update-title': 'Edit User Information',
  'update-button': 'Update',
  'password-button': 'Update password',
  'user-info': {
    'user-name': 'User name',
    'mail': 'Mail address',
    'authority': 'Authority',
    'bank': 'Bank',
  },
  'password': {
    'current': 'Current password',
    'new': 'New password',
    'confirmation': 'Confirm new password',
  },
}
