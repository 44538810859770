export default class UpdateDroneStatus {
  constructor(
    timestamp = undefined
    , gpsLocationAltitude = undefined
    , gpsLocationLat = undefined
    , gpsLocationLng = undefined
    , rtkLocationAltitude = undefined
    , rtkLocationLat = undefined
    , rtkLocationLng = undefined
    , rtkOrGpsValue = undefined
    , polylineLocationAltitude = undefined
    , polylineLocationLat = undefined
    , polylineLocationLng = undefined
    , flightTime = undefined
    , androidBattery = undefined
    , applicationStatus = 0
    , androidSysTemp = undefined
    , androidDiskSpace = undefined
    , deviceExtensionInfo = undefined
  ) {
    this.timestamp = timestamp
    this.gpsLocationAltitude = gpsLocationAltitude
    this.gpsLocationLat = gpsLocationLat
    this.gpsLocationLng = gpsLocationLng
    this.rtkLocationAltitude = rtkLocationAltitude
    this.rtkLocationLat = rtkLocationLat
    this.rtkLocationLng = rtkLocationLng
    this.rtkOrGpsValue = rtkOrGpsValue
    this.polylineLocationAltitude = polylineLocationAltitude
    this.polylineLocationLat = polylineLocationLat
    this.polylineLocationLng = polylineLocationLng
    this.flightTime = flightTime
    this.androidBattery = androidBattery
    this.applicationStatus = applicationStatus
    this.androidSysTemp = androidSysTemp
    this.androidDiskSpace = androidDiskSpace
    this.deviceExtensionInfo = deviceExtensionInfo
  }
}
