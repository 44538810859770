import MissionInfo from '@/models/mission-info'
import repository from '@/repositories/rest-api/repository'
import { toPlaceValue } from '@/utils/string'

let baseUrl = ''

const toPlace = (lat, lng) => {
  // 緯度経度がundefinedの場合は空文字として、両方undefinedや空文字の場合はnullを返す
  if (lat && lng) {
    // 両方値が設定されている場合は、緯度経度の順番でリストを返す
    return [String(lat), String(lng)]
  } else if (!lat && !lng) {
    // 両方undefinedや空文字の場合はnullを返す
    return null
  } else {
    // 片方設定されている場合は、未設定を空とみなして設定
    return [toPlaceValue(lat), toPlaceValue(lng)]
  }
}

const toModelMissionInfo = (missionInfo) => {
  let locationLat = undefined
  let locationLng = undefined
  if (missionInfo.place) {
    if (missionInfo.place[0]) locationLat = missionInfo.place[0]  // 値が空以外の場合取得
    if (missionInfo.place[1]) locationLng = missionInfo.place[1]  // 値が空以外の場合取得
  }
  return new MissionInfo(
    missionInfo.mission_id
    , missionInfo.name
    , missionInfo.pilot_id
    , missionInfo.date
    , missionInfo.target
    , locationLat
    , locationLng
    , missionInfo.status
    , missionInfo.total_number
    , missionInfo.uploaded_number
    , !missionInfo.display
  )
}

const get = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}`)
      return toModelMissionInfo(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const post = () => {
  return async (missionInfo) => {
    // バックエンド側で見るものだけ設定（口だけ存在し、見てないものは設定しない モデルにも保持していない）
    const params = missionInfo instanceof MissionInfo
                      ? {
                          'name': missionInfo.missionName,
                          'pilot_id': missionInfo.pilotId,
                          'date': missionInfo.missionDate,
                          'target': missionInfo.target,
                          'place': toPlace(missionInfo.locationLat, missionInfo.locationLng),
                        }
                      : undefined
    try {
      const response = await repository.post(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const put = () => {
  return async (missionInfo) => {
    // バックエンド側で見るものだけ設定（口だけ存在し、見てないものは設定しない モデルにも保持していない）
    const params = missionInfo instanceof MissionInfo
                      ? {
                          'name': missionInfo.missionName,
                          'pilot_id': missionInfo.pilotId,
                          'date': missionInfo.missionDate,
                          'target': missionInfo.target,
                          'place': toPlace(missionInfo.locationLat, missionInfo.locationLng),
                        }
                      : undefined
    try {
      const response = await repository.put(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const hide = () => {
  return async () => {
    try {
      const response = await repository.post(`${baseUrl}:hide`)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const show = () => {
  return async () => {
    try {
      const response = await repository.post(`${baseUrl}:show`)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function mission(basePath) {
  baseUrl = basePath
}

mission.prototype.get = get
mission.prototype.post = post
mission.prototype.put = put
mission.prototype.hide = hide
mission.prototype.show = show
