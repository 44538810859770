import { convertBooleanOfString } from '@/utils/string'

export default class CompanyAdminInfo {
  constructor(
    companyId = undefined
    , activateStatus = undefined
    , created = undefined
    , firstName = undefined
    , lastName = undefined
    , middleName = undefined
    , email = undefined
    , multiFactorAuth = null
    , role = undefined
  ) {
    this.companyId = companyId
    this.activateStatus = activateStatus
    this.created = created
    this.firstName = firstName
    this.lastName = lastName
    this.middleName = middleName
    this.email = email
    this.setMultiFactorAuth(multiFactorAuth)
    this.role = role
    this.fullName = this.fullName()
  }

  fullName() {
    return (this.firstName + ' ' + this.middleName + ' ' + this.lastName).replace('  ', ' ')
  }

  setFirstName(firstName) {
    this.firstName = firstName
  }
  setLastName(lastName) {
    this.lastName = lastName
  }
  setMiddleName(middleName) {
    this.middleName = middleName
  }
  setEmail(email) {
    this.email = email
  }
  setMultiFactorAuth(multiFactorAuth) {
    this.multiFactorAuth = convertBooleanOfString(multiFactorAuth)
  }
}
