import ThumbnailInfo from '@/models/thumbnail-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toThumbnailInfo = (thumbnailInfo, thumbnailId) => {
  return new ThumbnailInfo(
    thumbnailId
    , thumbnailInfo
  )
}

const toThumbnailInfos = (thumbnailInfos) => {
  let modelThumbnailInfos = []

  if (thumbnailInfos) {
    thumbnailInfos.forEach( function(thumbnailInfo) {
      modelThumbnailInfos.push(toThumbnailInfo(thumbnailInfo['image'], thumbnailInfo['image_id']))
    })
  }

  return modelThumbnailInfos
}

const getList = () => {
  /**
   * サムネイル画像情報取得
   * @param {number} startIndex サムネイルリスト中の取得開始番号
   * @param {number} conut サムネイル画像情報を取得する件数
   */
  return async (startIndex, conut) => {
    try {
      const params = {
        'start_index': startIndex,
        'count': conut,
      }
      const response = await repository.get(`${baseUrl}`, {params})
      return toThumbnailInfos(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const get = () => {
  /**
   * サムネイル画像情報取得
   * @param {string} thumbnailId 取得対象のサムネイル画像ID
   */
  return async (thumbnailId) => {
    try {
      // サムネイル画像IDは、URLエンコード対象の文字が含まれるのでエンコードを行う
      const encodeThumbnailId = encodeURIComponent(thumbnailId)

      const response = await repository.get(`${baseUrl}/${encodeThumbnailId}`)
      return toThumbnailInfo(response.data.body, thumbnailId)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function thumbnailImages(basePath) {
  baseUrl = basePath
  return {
    getList: getList(),
    get: get(),
  }
}
