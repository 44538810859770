import PilotInfo from '@/models/pilot-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelPilotInfo = (apiResponseBody) => {
  return new PilotInfo(
    apiResponseBody.id
    , apiResponseBody.status
    , apiResponseBody.created
    , apiResponseBody.first_name
    , apiResponseBody.last_name
    , apiResponseBody.middle_name
    , apiResponseBody.email
    , apiResponseBody.multi_factor_auth
    , apiResponseBody.role
  )
}

const toModelPilotInfos = (pilotInfos) => {
  let modelPilotInfos = []

  pilotInfos.forEach( function(pilotInfo) {
    modelPilotInfos.push(toModelPilotInfo(pilotInfo))
  })

  return modelPilotInfos
}

const getList = () => {
  return async (companyAdminUserId) => {
    const params = {
      'user_id': companyAdminUserId,
    }
    try {
      const response = await repository.get(`${baseUrl}`, {params})
      return toModelPilotInfos(response.data.body)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const post = () => {
  return async (companyAdminUserId, pilotInfo) => {
    const params = pilotInfo instanceof PilotInfo
                      ? {
                          'first_name': pilotInfo.firstName,
                          'last_name': pilotInfo.lastName,
                          'middle_name': pilotInfo.middleName,
                          'email': pilotInfo.email,
                          'multi_factor_auth': pilotInfo.multiFactorAuth,
                        }
                      : undefined
    try {
      const response = await repository.post(`${baseUrl}?user_id=${companyAdminUserId}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const put = () => {
  return async (pilotInfo) => {
    const params = pilotInfo instanceof PilotInfo
                      ? {
                          'multi_factor_auth': pilotInfo.multiFactorAuth,
                        }
                      : undefined
    try {
      const response = await repository.put(`${baseUrl}`, params)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

const deleteMethod = () => {
  return async () => {
    try {
      const response = await repository.delete(`${baseUrl}`)
      return response
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function pilot(basePath) {
  baseUrl = basePath
}

pilot.prototype.getList = getList
pilot.prototype.post = post
pilot.prototype.put = put
pilot.prototype.delete = deleteMethod
