// TODO エラー情報については整理したタイミングでリファクタすることを想定

const BAD_REQUEST = 400
const UNAUTHORIZED = 401
const FORBIDDEN = 403
const NOT_FOUND = 404
const UNPROCESSABLE_ENTITY = 422
const INTERNAL_SERVER_ERROR = 500

export default class ErrorInfo {
  /**
   * コンストラクタ（axiosのレスポンス結果から必要なものを格納する形を想定）
   * @param {string} code axios内部でのエラーを識別するコード
   * @param {string} message エラーメッセージの要約
   * @param {number} httpStatusCode HTTPレスポンスステータスコード
   * @param {string} httpStatusText HTTPレスポンスステータステキスト
   * @param {string} backendErrorCode バックエンドが設定しているエラーコード
   */
  constructor(
    code = 'UNEXPECTED_ERROR'
    , message = 'An unexpected error has occurred.'
    , httpStatusCode = 500
    , httpStatusText = 'Internal Server Error'
    , backendErrorCode = ''
  ) {
    this.code = code
    this.message = message
    this.httpStatusCode = httpStatusCode
    this.httpStatusText = httpStatusText
    this.backendErrorCode = backendErrorCode
  }

  getErrorCodeI18nKey() {
    switch (this.httpStatusCode) {
      case BAD_REQUEST:
        return 'message.error.bad-request'
      case UNAUTHORIZED:
        return 'message.error.auth-failed'
      case FORBIDDEN:
        return 'message.error.not-access'
      case NOT_FOUND:
        return 'message.error.not-found'
      case UNPROCESSABLE_ENTITY:
        return 'message.error.invalid-data'
      case INTERNAL_SERVER_ERROR:
        return 'message.error.server-error'
      default:
        return 'message.error.get-failed'
    }
  }
}
