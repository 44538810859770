import DeviceInfo from '@/models/device-info'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toModelDeviceInfo = (deviceInfo) => {
  return new DeviceInfo(
    deviceInfo['device_uuid']
    , deviceInfo['region']
  )
}

const get = () => {
  return async () => {
    try {
      const response = await repository.get(`${baseUrl}`)
      let responseDataBody = response.data.body

      // レスポンスがnullの場合は空として扱う（ミッションとデバイスが紐づいていない場合を想定）
      if (!responseDataBody) {
        responseDataBody = {}
      }

      return toModelDeviceInfo(responseDataBody)
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function device(basePath) {
  baseUrl = basePath
  return {
    get: get(),
  }
}
