import ImageMetadata from '@/models/image-metadata'
import MapPosition from '@/models/map-position'
import repository from '@/repositories/rest-api/repository'

let baseUrl = ''

const toMapPosition = (gps) => {
  // gpsが存在しない場合は、MapPositionはundefined
  if (!gps) return undefined

  const lat = gps['latitude'] ? Number(gps['latitude']) : undefined
  const lng = gps['longitude'] ? Number(gps['longitude']) : undefined
  const numberAltitude = gps['altitude'] ? Number(gps['altitude']) : undefined
  const altitude = isNaN(numberAltitude) ? 0 : numberAltitude
  if (isNaN(lat) || isNaN(lng)) {
    return undefined
  } else {
    return new MapPosition(lat, lng, altitude)
  }
}

const toImageMetadata = (imageMetadata) => {
  return new ImageMetadata(
    imageMetadata['image_id']
    , toMapPosition(imageMetadata['gps'])
  )
}

/**
 * APIから返却された画像のメタデータリストをモデルに詰め替え
 * @param {*} imageMetadataList APIから返却された画像のメタデータリスト
 * @return {ImageMetadata[]}
 */
const toImageMetadataList = (imageMetadataList) => {
  /** @type {ImageMetadata[]} */
  let modelImageMetadataList = []

  imageMetadataList.forEach(imageMetadata => {
    modelImageMetadataList.push(toImageMetadata(imageMetadata))
  })

  return modelImageMetadataList
}
const getList = () => {
  /**
   * 画像のメタデータリスト取得
   * @param {string} token 差分取得で使用するトークン（初期レコードから取得する場合は、未指定）
   * @return {Promise<{imageMetadata: ImageMetadata[], nextToken: string} | ErrorInfo>} 画像のメタデータモデルのリストと差分取得で使用するトークンまたは、エラーモデル
   */
  return async (token) => {
    try {
      const params = token ? { 'next_token_pk': token.pk, 'next_token_sk': token.sk } : undefined

      const response = await repository.get(`${baseUrl}`, {params})
      const imageMetadataList = toImageMetadataList(response.data.body['meta_list'])
      const nextToken = response.data.body['next_token']

      return {imageMetadataList, nextToken}
    } catch(error) {
      return Promise.reject(error)
    }
  }
}

export default function metaData(basePath) {
  baseUrl = basePath
  return {
    getList: getList(),
  }
}
