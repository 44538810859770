export default {
  'form': {
    'title': 'Camera settings',
    'sub-title': 'Camera setting %{subject}',
    'still-image-save-destination': 'Image save destination',
    'still-file-format': 'File format',
    'jpeg-quality': 'JPEG Q-value',
    'image-size': 'Image size',
    'focus-mode': 'Focus mode',
    'exposure-program-mode': 'Exposure',
    'white-balance': 'White balance',
    'exposure-bias-compensation': 'Exposure compensation',
    'iso': 'ISO sensitivity',
    'shutter-speed': 'Shutter speed',
    'f-number': 'F-number',
    'reacquire-button': 'Reacquire',
    'save-button': 'Save',
    'one-shoot-button': 'One shoot',
    'start-interval-shoot-button': 'Start interval shoot',
    'stop-interval-shoot-button': 'Stop interval shoot',
    'not-connect': 'Connect the camera and mobile device and start the mission with the app.',
    'response-success': 'Succeeded.',
    'response-error': 'Failed.',
    'connect-camera-failed': 'Failed to connect to camera. Please check whether the camera and mobile device are connected.',
  },
}
