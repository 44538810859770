export default {
  'form': {
    'title': 'カメラ設定',
    'sub-title': '%{subject} カメラ設定',
    'still-image-save-destination': '画像の保存先',
    'still-file-format': 'ファイル形式',
    'jpeg-quality': 'JPEGのQ値',
    'image-size': '画像サイズ',
    'focus-mode': 'フォーカスモード',
    'exposure-program-mode': '露出プログラムモード',
    'white-balance': 'ホワイトバランス',
    'exposure-bias-compensation': '露出補正',
    'iso': 'ISO感度',
    'shutter-speed': 'シャッタースピード',
    'f-number': 'F値',
    'reacquire-button': '再取得',
    'save-button': '保存',
    'one-shoot-button': '1枚撮影',
    'start-interval-shoot-button': 'インターバル撮影開始',
    'stop-interval-shoot-button': 'インターバル撮影停止',
    'not-connect': 'カメラとモバイルデバイスを接続し、アプリでミッションを開始して下さい。',
    'response-success': '成功しました。',
    'response-error': '失敗しました。',
    'connect-camera-failed': 'カメラとの接続に失敗しました。カメラとモバイルデバイスが接続されているかご確認ください。',
  },
}
