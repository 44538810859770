import pilot from './pilot-repository'
import missions from './missions/repository-factory'

export default function pilots(basePath, userId) {
  const pilotRepository = userId ? new pilot(`${basePath}/${userId}`) : new pilot(`${basePath}`)
  return {
    getList: pilotRepository.getList(),
    post: pilotRepository.post(),
    put: pilotRepository.put(),
    delete: pilotRepository.delete(),
    missions: (missionId) => missions(`${basePath}/${userId}/missions`, missionId),
  }
}
