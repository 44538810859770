import bestShot from './en/best-shot'
import bucket from './en/bucket'
import camera from './en/camera'
import common from './en/common'
import companies from './en/companies'
import contents from './en/contents'
import device from './en/device'
import error from './en/error'
import exif from './en/exif'
import link from './en/link'
import menu from './en/menu'
import mission from './en/mission'
import user from './en/user'
import users from './en/users'
import validation from './en/validation'

export default {
  message: {
    bestShot,
    bucket,
    camera,
    common,
    companies,
    contents,
    device,
    error,
    exif,
    link,
    menu,
    mission,
    user,
    users,
    validation,
  },
}
