import bestShot from './ja/best-shot'
import bucket from './ja/bucket'
import camera from './ja/camera'
import common from './ja/common'
import companies from './ja/companies'
import contents from './ja/contents'
import device from './ja/device'
import error from './ja/error'
import exif from './ja/exif'
import link from './ja/link'
import menu from './ja/menu'
import mission from './ja/mission'
import user from './ja/user'
import users from './ja/users'
import validation from './ja/validation'

export default {
  message: {
    bestShot,
    bucket,
    camera,
    common,
    companies,
    contents,
    device,
    error,
    exif,
    link,
    menu,
    mission,
    user,
    users,
    validation,
  },
}
