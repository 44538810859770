export default {
  'not-number': 'Please enter a number',
  'out-range': 'Please enter within the range of {min} to {max}',
  'enter-first-name': 'Please enter your first name',
  'incorrect-first-name': 'Incorrect first name entered ( {errorcode} )',
  'enter-last-name': 'Please enter your last name',
  'incorrect-last-name': 'Incorrect last name entered ( {errorcode} )',
  'enter-email': 'Please enter a valid email address',
  'email-in-use': 'This email address is already in use ( {errorcode} )',
  'select-mfa': 'Please select multi-factor authentication',
  'create-update-failed': 'Create/update failed ( {errorcode} )',
}
