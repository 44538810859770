import BucketInfo from '@/models/bucket-info'
import repository from '@/repositories/rest-api/repository'
import { getUserInfo } from '@/repositories/browser-data/repository'

const basePath = () => {
  return `/api/v1/tenants/${getUserInfo().tenantId}/buckets`
}

const toBucketInfo = (bucketInfo) => {
  return new BucketInfo(
    bucketInfo['bucket_name']
  )
}

const toBucketInfos = (bucketInfos) => {
  let modelBucketInfos = []

  bucketInfos.forEach(bucketInfo => {
    modelBucketInfos.push(toBucketInfo(bucketInfo))
  })

  return modelBucketInfos
}

export default {
  getBucketList() {
    return repository.get(`${basePath()}`)
      .then(function (response) {
        return toBucketInfos(response.data.body)
      })
      .catch(function (error) {
        return error
      })
      .finally(function () {})
  },
  bucketCooperate(bucketName) {
    return repository.post(`${basePath()}/${bucketName}`)
      .then(function (response) {
        return toBucketInfo(response.data.body)
      })
      .catch(function (error) {
        return error
      })
      .finally(function () {})
  },
}
